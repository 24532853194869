<template>
    <div id="Intro"> 
      <body class="">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
              <div class="carousel-inner vh-100">
                <div class="carousel-item active">
                    <div class="vh-100 position-relative">
                        <div class="bg-gradient-success vh-60"></div>
                        <div class="nicole-member-center">
                            <div class="phone mx-auto">
                            <img src="assets/img/img01.png" class="w-100">
                            </div>
                            <div class="text-center text-dark text-card-title  contextbox">
                                一機在手，行動學習<br>海量文章、多元主題<br>英文閱讀、聽力能力一把抓<br>整個世界都是我的行動英文教室
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active bg-success"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="1" class="bg-success-soft2 "></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="2" class="bg-success-soft2"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="2" class="bg-success-soft2"></li>
                      </ol>
                </div>
                <div class="carousel-item ">
                    <div class="vh-100 position-relative">
                        <div class="bg-gradient-success vh-60"></div>
                        <div class="nicole-member-center">
                            <div class="phone mx-auto">
                            <img src="assets/img/img02.png" class="w-100">
                            </div>
                            <div class="text-center text-dark text-card-title contextbox">
                                &nbsp;<br>分段學習，靈活運用零碎時間<br>從文章朗讀、名師講解到課後練習<br>能力培養一應俱全，學習不卡關
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="0" class="bg-success-soft2"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="1" class="active bg-success"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="2" class="bg-success-soft2"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="2" class="bg-success-soft2"></li>
                      </ol>
                </div>
                <div class="carousel-item">
                    <div class="vh-100 position-relative">
                        <div class="bg-gradient-success vh-60"></div>
                        <div class="nicole-member-center">
                            <div class="phone mx-auto">
                            <img src="assets/img/img03.png" class="w-100">
                            </div>
                            <div class="text-center text-dark text-card-title contextbox">
                                &nbsp;<br>名師講解，深入淺出<br>文章剖析、重點整理，精準到位<br>專人解決疑難雜症，學習有效率
                            </div>
                        </div>
                    </div>
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="0" class="bg-success-soft2"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="1" class="bg-success-soft2"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="2" class="active bg-success"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="2" class="bg-success-soft2"></li>
                      </ol>
                </div>       
                  <div class="carousel-item">
                    <div class="vh-100 position-relative">
                        <div class="bg-gradient-success vh-60"></div>
                        <div class="nicole-member-center">
                            <div class="phone mx-auto">
                            <img src="assets/img/img04.png" class="w-100">
                            </div>
                            <div class="text-center text-dark text-card-title contextbox">
                                客製化專屬學習課程<br>自選等級與主題，學習更符合需求<br>學習狀態一覽無遺，自我檢視更確實
                            </div>
                            <div class="text-center">
                          <button type="button" class="btn btn-lg  btn-success rounded-pill phone  text-card-title" @click="onStartup">立即體驗</button></div>
                        </div>
                    </div>                

                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="0" class="bg-success-soft2"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="1" class="bg-success-soft2"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="2" class="bg-success-soft2"></li>
                        <li data-target="#carouselExampleIndicators" data-bs-slide-to="2" class="active bg-success"></li>
                      </ol>
                </div>
              </div>
            
              <a class="carousel-control-prev w-20" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next w-20" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>    
        </body>
    </div>
</template>


<script>

export default {
    name: 'Intro',
    components: {
    },
    data () {
    return { 
        }
    },
    methods: {
      onStartup(){
        var json = {
                key: "intro_home",
                object: "Y"
        };
        this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify(json), this.saveLocalDataVue);
      },
      saveLocalDataVue(data){
        this.$router.push(
        {
            name : 'Home',
        });
      },
    },
    mounted() {
    },
    created(){          
      this.$nextTick(() => {
          $('#carouselExampleIndicators').on('slide.bs.carousel', function (e) {
            var slideFrom = $(this).find('.active').index();
            var slideTo = $(e.relatedTarget).index();
            console.log(`slideFrom => ${slideFrom} slideTo => ${slideTo}`);
            if((slideFrom == 3 && slideTo == 0) || (slideFrom == 0 && slideTo == 3)){
              e.preventDefault();
            }
          });
          $("#carouselExampleIndicators").carousel(0);
      });        
    }
}
</script>